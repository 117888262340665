import { t } from "ttag";

import NewItemMenu from "metabase/containers/NewItemMenu";
import type { CollectionId, User } from "metabase-types/api";

import { NewButton, NewButtonText } from "./NewItemButton.styled";

export interface NewItemButtonProps {
  currentUser: User;
  collectionId?: CollectionId;
}

const NewItemButton = ({ currentUser, collectionId }: NewItemButtonProps) => {
  return (
    <NewItemMenu
      trigger={
        <NewButton primary icon="add" aria-label={t`New`}>
          <NewButtonText>{t`New`}</NewButtonText>
        </NewButton>
      }
      collectionId={collectionId}
      currentUser={currentUser} 
    />
  );
};

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default NewItemButton;
