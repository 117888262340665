import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import CS from "metabase/css/core/index.css";
import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { dark, height, width } = this.props;
    return (
     
      <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="29"
      height="32"
      viewBox="0 0 29 32"
    >
      <defs>
        <clipPath id="clip-immibox_final_monogram">
          <rect width="29" height="32" />
        </clipPath>
      </defs>
      <g
        id="immibox_final_monogram"
        data-name="immibox final monogram"
        clipPath="url(#clip-immibox_final_monogram)"
      >
        <g id="Group_20534" data-name="Group 20534">
          <g id="Group_15713" data-name="Group 15713" transform="translate(0 13.4)">
            <path
              id="Path_6818"
              data-name="Path 6818"
              d="M295.852,470.4,274.95,489H285.4l10.451-9.3Z"
              transform="translate(-274.95 -470.4)"
              fill="#232868"
            />
            <path
              id="Path_6819"
              data-name="Path 6819"
              d="M280.175,500.621l-5.225-4.651v9.3Z"
              transform="translate(-274.95 -486.672)"
              fill="#ec1c24"
            />
          </g>
          <g id="Group_15714" data-name="Group 15714">
            <path
              id="Path_6820"
              data-name="Path 6820"
              d="M295.852,433.55l-20.9,18.6H285.4l10.451-9.3Z"
              transform="translate(-274.95 -433.55)"
              fill="#232868"
            />
            <path
              id="Path_6821"
              data-name="Path 6821"
              d="M280.175,463.771l-5.225-4.651v9.3Z"
              transform="translate(-274.95 -449.822)"
              fill="#ec1c24"
            />
          </g>
        </g>
      </g>
    </svg>

    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
